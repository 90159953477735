const initDropdown = () => {
  const dropdownContainers = document.querySelectorAll('.dropdown-js');

  if (!dropdownContainers) {
    return;
  }

  dropdownContainers.forEach((dropdown) => {
    dropdown.addEventListener('click', (evt) => {
      if (!dropdown.classList.contains('active')) {
        dropdown.classList.remove('active');
      }

      if (evt.target.closest('.dropdown-open-js')) {
        dropdown.classList.toggle('active');
      }
    });

    window.addEventListener('click', (evt) => {
      if (!evt.target.closest('.dropdown-js')) {
        dropdown.classList.remove('active');
      }
    });
  });
};
export {initDropdown};
