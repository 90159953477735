const dropsArea = document.querySelectorAll('.file-upload-field');


const dragFile = () => {
  if (!dropsArea) {
    return;
  }

  dropsArea.forEach((dropArea) => {
    // Загрузка фото
    const loadInput = dropArea.querySelector('.file-upload-field input');
    const loadLabel = dropArea.querySelector('.file-upload-field label');

    const showFiles = () => {
      if (dropArea.closest('.modal--upload')) {
        dropArea.closest('.modal--upload').classList.add('modal--upload-active');
      }
    };

    loadInput.addEventListener('change', showFiles);

    const preventDefaults = (evt) => {
      evt.preventDefault();
      evt.stopPropagation();
    };

    // Перетаскивание фото

    if (dropArea) {
      const highlight = () => {
        loadLabel.classList.add('highlight');
      };

      const unHighlight = () => {
        loadLabel.classList.remove('highlight');
      };

      ['dragenter', 'dragover', 'dragleave', 'drop'].forEach((eventName) => {
        dropArea.addEventListener(eventName, preventDefaults);
      });

      ['dragenter', 'dragover'].forEach((eventName) => {
        dropArea.addEventListener(eventName, highlight);
      });

      ['dragleave', 'drop'].forEach((eventName) => {
        dropArea.addEventListener(eventName, unHighlight);
      });

      dropArea.addEventListener('drop', showFiles);
    }
  });
};

export {dragFile};
