const standList = document.querySelector('.upgrade-stand__list');

const renderCheckboxLabels = () => {
  const standCardHeaders = standList.querySelectorAll('.stand-card__header');

  standCardHeaders.forEach((standCardHeader) => {
    const checkbox = standCardHeader.querySelector('input');
    const labelSelect = standCardHeader.querySelector('.stand-card__label-select');
    const labelDismiss = standCardHeader.querySelector('.stand-card__label-dismiss');

    checkbox.classList.add('visually-hidden');

    if (checkbox.checked) {
      labelSelect.style.display = 'none';
      labelDismiss.style.display = 'block';
    } else {
      labelSelect.style.display = 'block';
      labelDismiss.style.display = 'none';
    }
  });
};

const renderSubmitBtn = () => {
  const upgradeStand = standList.closest('.upgrade-stand');
  const standCardHeaders = standList.querySelectorAll('.stand-card__header');

  for (let i = 0; i < standCardHeaders.length; i++) {
    const checkbox = standCardHeaders[i].querySelector('input');

    if (checkbox.checked) {
      upgradeStand.classList.add('upgrade-stand--stand-selected');
      return;
    }

    upgradeStand.classList.remove('upgrade-stand--stand-selected');
  }
};

const onStandCardCheckboxChange = (evt) => {
  const target = evt.target.closest('input');
  const checkboxes = standList.querySelectorAll('input[name^="stand-"]');

  checkboxes.forEach((checkbox) => {
    if (checkbox !== target) {
      checkbox.checked = false;
    }
  });

  renderCheckboxLabels();
  renderSubmitBtn();
};

const initModalStandCardCheckboxes = () => {
  if (standList) {
    renderCheckboxLabels();
    renderSubmitBtn();

    standList.addEventListener('change', onStandCardCheckboxChange);
  }
};

export {initModalStandCardCheckboxes};
