const showNotifiction = (template, card = {}, notifText = 'Товар добавлен') => {
  const container = document.querySelector('.notifications');
  const TIMEOUT = Number(container.dataset.timeout);
  const cloneMessage = template.cloneNode(true);


  const closeNotificationButton = cloneMessage.querySelector('.notification__close-btn');

  if (card) {
    cloneMessage.querySelector('.notification__message-text').textContent = card.productName;
    cloneMessage.querySelector('.notification__message-additional').textContent = card.productPrice;
    if (card.value) {
      //cloneMessage.querySelector('.notification__count').textContent = `, ${Number(card.value)} шт `;
    }
    cloneMessage.querySelector('.notification__tooltip').textContent = notifText;
  }

  container.appendChild(cloneMessage);

  setTimeout(() => {
    cloneMessage.classList.add('active');
    cloneMessage.classList.remove('visually-hidden');

    cloneMessage.style.height = `${cloneMessage.clientHeight}px`;
  }, 10);

  closeNotificationButton.addEventListener('click', () => {
    clearTimeout(closeTimeout);
    removeNotification(cloneMessage);
  });

  let closeTimeout = setTimeout(() => {
    removeNotification(cloneMessage);
  }, TIMEOUT);
};

const removeNotification = (notification) => {
  const container = document.querySelector('.notifications');
  notification.classList.add('remove');

  setTimeout(() => {
    notification.style.height = '0';
  }, 300);

  setTimeout(() => {
    container.removeChild(notification);
  }, 600);
};

export {showNotifiction, removeNotification};
