const cart = document.querySelector('.cart');
const cartBtns = document.querySelectorAll('[data-modal="cart"]');

const openCart = () => {
  const overlay = cart.querySelector('.cart__overlay');
  const closeBtn = cart.querySelector('.cart__close-btn');

  window.disableBodyScroll(cart, {
    reserveScrollBarGap: true,
  });

  cart.classList.add('cart--opened');

  closeBtn.addEventListener('click', oncloseBtnClick);
  overlay.addEventListener('click', onOverlayClick);
  document.addEventListener('keydown', onEscPress);
};

const closeCart = () => {
  const overlay = cart.querySelector('.cart__overlay');
  const closeBtn = cart.querySelector('.cart__close-btn');

  setTimeout(() => {
    window.enableBodyScroll(cart);
  }, 200);

  cart.classList.remove('cart--opened');

  closeBtn.removeEventListener('click', oncloseBtnClick);
  overlay.removeEventListener('click', onOverlayClick);
  document.removeEventListener('keydown', onEscPress);
};

const oncloseBtnClick = () => {
  closeCart();
};

const onOverlayClick = () => {
  closeCart();
};

const onEscPress = (evt) => {
  const isEscKey = evt.key === 'Escape' || evt.key === 'Esc';

  if (isEscKey && cart.classList.contains('cart--opened')) {
    evt.preventDefault();

    closeCart();
  }
};

const setupCart = (btns) => {
  if (btns) {
    btns.forEach((btn) => {
      btn.addEventListener('click', (evt) => {
        evt.preventDefault();

        openCart();
      });
    });
  }
};

const initCart = () => {
  if (cart && cartBtns.length) {
    setupCart(cartBtns);
  }
};

export {initCart};
