const initJsLinks = () => {
    let jsLinks = document.querySelectorAll('.js-link');
    if (jsLinks.length) {
        jsLinks.forEach((jsLink) => {
            jsLink.addEventListener('click', (e) => {
                e.preventDefault();
                const dataHref = jsLink.dataset.href;
                if (dataHref) {
                    location.href = dataHref;
                    return false;
                }
            });
        });
    }
};

export {initJsLinks};
