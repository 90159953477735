const initFirefoxNotification = () => {
  const ua = navigator.userAgent;
  if (ua.search(/Firefox/) > 0) {
    const notification = document.querySelector('.js-ff-notification');
    const closeBtn = notification.querySelector('.notification__close-btn');
    const checked = sessionStorage.getItem('checked');

    if (!checked) {
      notification.classList.add('active');
    }

    closeBtn.addEventListener('click', () => {
      notification.classList.remove('active');

      setTimeout(() => {
        notification.parentElement.removeChild(notification);
      }, 300);
      sessionStorage.setItem('checked', true);
    });

  }
};

export {initFirefoxNotification};
