import axios from 'axios';

const renderContextmenu = (data) => {
  const tableItems = document.querySelector('.js-table-items');
  const menuClone = data.pattern.cloneNode(true);
  const cloneOption = menuClone.querySelector('li');
  menuClone.removeChild(cloneOption);

  data.options.forEach((option, i) => {
    if (data.multiple) {
      const itemClone = data.pattern.querySelector('li').cloneNode(true);
      const itemInput = itemClone.querySelector('input');
      const itemLabel = itemClone.querySelector('label');
      const labelSpan = itemLabel.querySelector('.checkbox-wrapper__text');

      const id = `date-${i}`;

      labelSpan.textContent = option.label;
      itemLabel.setAttribute('for', id);
      itemInput.value = option.value;
      itemInput.id = id;

      if (data.dataset) {
        menuClone.dataset.context = data.dataset;
      }

      menuClone.appendChild(itemClone);
    } else {
      const itemClone = data.pattern.querySelector('li').cloneNode(true);
      itemClone.textContent = option.label;
      itemClone.id = option.value;
      itemClone.dataset.price = option.price;

      if (data.dataset) {
        menuClone.dataset.context = data.dataset;
      }

      menuClone.appendChild(itemClone);
    }
  });
  tableItems.appendChild(menuClone);
};

const parseNum = (str) => {
  let num;
  let replace = str.toString().replace(/\D/g, '');

  if (str) {
    num = parseInt(replace, 10);
  }

  return num;
};

const renderDate = (data, cell, cols) => {
  const pattern = document.querySelector('.table--pattern .table__wrapper');

  const tableClone = pattern.cloneNode(true);
  const row = tableClone.querySelector('.js-context-menu');
  row.parentElement.removeChild(row);

  cell.dataset.context = cols.key;
  const cellSpan = cell.querySelector('span');

  if (cell.dataset.context === 'dates') {
    const regexpMonth = /[a-zA-Z-а-яёА-ЯЁ]+/;
    const regexpReplace = /\s[a-zA-Z-а-яёА-ЯЁ]+/;

    data.forEach((element) => {
      cellSpan.style.display = 'flex';
      cellSpan.style.flexDirection = 'column';
      const item = document.createElement('span');

      if (cellSpan.querySelector(`[data-month="${element.match(regexpMonth)}"]`)) {
        const monthSpan = cellSpan.querySelector(`[data-month="${element.match(regexpMonth)}"]`);
        const months = monthSpan.querySelectorAll('span');

        months.forEach((month) => {
          const replacedMonth = month.textContent.replace(regexpReplace, ', ');
          month.textContent = replacedMonth;
        });

        item.textContent = element;
        item.dataset.value = element;
        monthSpan.appendChild(item);
      } else {
        item.dataset.month = element.match(regexpMonth);
        cellSpan.appendChild(item);
        const item2 = document.createElement('span');
        item2.dataset.value = element;
        item2.textContent = element;
        item.appendChild(item2);
      }
    });

  } else {
    cellSpan.textContent = data;
  }
};

const renderTable = (data) => {
  const container = document.querySelector('.js-table');
  const pattern = document.querySelector('.table--pattern .table__wrapper');
  const rowPattern = pattern.querySelector('.js-context-menu');

  const tableClone = pattern.cloneNode(true);
  const tableFooter = tableClone.querySelector('.table__footer');
  const tBody = tableClone.querySelector('tbody');
  const rowHeader = tableClone.querySelectorAll('th');

  if (data.table) {
      const emptyTable = document.querySelector('.js-table .table__wrapper');
      emptyTable.parentElement.removeChild(emptyTable);

      const row = tableClone.querySelector('.js-context-menu');
      row.parentElement.removeChild(row);


      for (let i = 0; i < data.table.cols.length; i++) {
        const colsKey = data.table.cols[i].key;
        console.log(colsKey);
        const colsTitle = data.table.cols[i].title;
        const colsType = data.table.cols[i].type;
        const colsElement = rowHeader[i];
        console.log(colsElement);

        if (typeof data.table.cols[i].multiple === 'boolean') {
          const colsMultiple = data.table.cols[i].multiple;
          colsElement.dataset.multiple = colsMultiple;

          if (colsMultiple) {
            renderContextmenu({
              options: data.table.cols[i].options,
              multiple: colsMultiple,
              pattern: document.querySelector('.js-table-select-multiple-pattern .table-contextmenu'),
              dataset: data.table.cols[i].key,
            });
          } else {
            renderContextmenu({
              options: data.table.cols[i].options,
              multiple: colsMultiple,
              pattern: document.querySelector('.js-table-select-pattern .table-contextmenu'),
              dataset: data.table.cols[i].key,
            });
          }
        }

        colsElement.dataset.key = colsKey;
        colsElement.textContent = colsTitle;
        colsElement.dataset.type = colsType;
      }

    data.table.rows.forEach((dataRow, j) => {
      let rowData = [
        dataRow.standNum,
        dataRow.company,
        dataRow.product,
        dataRow.quantity,
        dataRow.price,
        dataRow.total,
      ];

      if (dataRow.dates) {
        rowData.push(dataRow.dates);
      }

      rowData.push(dataRow.comment);

      const rowClone = rowPattern.cloneNode(true);
      const cells = rowClone.querySelectorAll('td');

      cells.forEach((cell, i) => {
        const tableMenu = cell.classList.contains('table__menu');
        if (tableMenu) {
          renderDate(rowData[i], cell, data.table.cols[i]);

          if (cell.dataset.context === 'product') {
            const span = cell.querySelector('span');
            span.dataset.itemid = data.table.rows[j].itemid;
            span.dataset.bid = data.table.rows[j].bid;
          }
        } else {
          cell.textContent = rowData[i];
        }
      });

      tBody.insertBefore(rowClone, tableFooter);
    });

    container.appendChild(tableClone);

    const wrapper = container.querySelector('.table__wrapper');

    wrapper.addEventListener('scroll', () => {
      const menu = document.querySelectorAll('.table__menu');
      const contextmenu = document.querySelectorAll('.table-contextmenu');
      contextmenu.forEach((element) => {
        element.classList.remove('active');
      });
      menu.forEach((element) => {
        element.classList.remove('active');
      });
    });

    document.querySelector('.js-table').dispatchEvent(new CustomEvent('table:rendered'))
    const cells = wrapper.querySelectorAll('.js-table td');

    cells.forEach((cell) => {
      if (cell.closest('tr').classList.contains('js-context-menu')) {
        cell.addEventListener('contextmenu', (e) => {
          e.preventDefault();
        });
      }
    });
  }
};

export default class Table {
  constructor(tables) {
    this.synced = true;
    this.tables = tables;
    this.menu = document.querySelector('.table-menu');
    this.wrapper = document.querySelector('.table__wrapper');
  }

  touch() {
    this.synced = false;
    console.log("touched");
  }

  untouch() {
    this.synced = true;
    console.log("untouched");
  }

  checkBrowser() {
    const ua = navigator.userAgent;
    if (ua.search(/Firefox/) > 0) {
      this.menu.classList.add('firefox');
    }
  }

  delay(ms) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, ms);
    });
  }

  hideMenu() {
    this.menu.classList.remove('active');
    this.menu.style.left = '';
    this.menu.style.top = '';
  }

  createRequest() {
    const url = document.querySelector('.js-table').dataset.tableUrl;
    const Request = new XMLHttpRequest();

    Request.onreadystatechange = function () {
      if (Request.readyState === XMLHttpRequest.DONE) {
        if (Request.status === 200) {
          const data = JSON.parse(this.responseText);
          renderTable(data);
        }
      }
    };

    Request.open('GET', url, true);
    Request.send();
  }

  addToCart() {
    const button = document.querySelector('[data-add-to-cart]');
    button.addEventListener('click', () => {
      //button.classList.add('disabled-button-tmp');
      const contenteditableCells = document.querySelectorAll('.js-table [contenteditable="true"]');
      const menuCells = document.querySelectorAll('.js-table .table__menu');

      contenteditableCells.forEach((cell) => {
        if (!cell.classList.contains('table__comment')) {
          if (cell.textContent === '') {
            cell.classList.add('invalid');
          } else {
            cell.classList.remove('invalid');
          }
        }
      });

      menuCells.forEach((menu) => {
        if (menu.classList.contains('table__menu')) {
          const span = menu.querySelector('span');

          if (span.textContent === '') {
            menu.classList.add('invalid');
          } else {
            menu.classList.remove('invalid');
          }
        }
      });

      const invalidElements = document.querySelectorAll('.js-table .invalid');
      if (!invalidElements[0]) {
        const url = button.dataset.addToCart;
        const tableData = JSON.parse(this.tableToJson());
        tableData.actionVar = button.dataset.action;
        tableData.category = button.dataset.category;
        tableData.eventId = button.dataset.eventid;
        tableData.eventCode = button.dataset.eventcode;
        tableData.oid = button.dataset.oid;

        axios.post(url, tableData).then((response) => {
          const data = response.data;
          this.untouch();
          location.reload();
        });
      } else {
        button.classList.remove('disabled-button-tmp');
      }
    });
  }

  tableToJson() {
    const dataCols = [];
    const dataRows = [];

    const table = document.querySelector('.js-table');
    const th = table.querySelectorAll('th');
    const tableRows = table.querySelectorAll('.js-context-menu');

    th.forEach((item, i) => {
      if (item.dataset.multiple) {
        dataCols[i] = {
          'key': item.dataset.key,
          'title': item.textContent,
          'type': item.dataset.type,
          'multiple': item.dataset.multiple,
        };
      } else {
        dataCols[i] = {
          'key': item.dataset.key,
          'title': item.textContent,
          'type': item.dataset.type,
        };
      }
    });

    tableRows.forEach((row, i) => {
      const datesArr = [];
      let comment = '';
      const cells = row.querySelectorAll('td');
      let dates;

      if (cells[6]) {
        dates = cells[6].querySelectorAll('[data-value]');
        dates.forEach((date) => {
          datesArr.push(date.dataset.value);
        });
      }
      if (cells[7]) {
        comment = cells[7].textContent;
      } else if (cells[6]) {
        comment = cells[6].textContent;
      }

      dataRows[i] = {
        'standNum': cells[0].textContent,
        'company': cells[1].textContent,
        'product': cells[2].querySelector('span').textContent,
        'product_id': cells[2].querySelector('span').dataset.itemid,
        'quantity': cells[3].textContent,
        'price': cells[4].textContent,
        'total': cells[5].textContent,
        'dates': datesArr,
        'comment': comment,
        'itemid': cells[2].querySelector('span').dataset.itemid,
        'bid': cells[2].querySelector('span').dataset.bid,
      };
    });

    const json = {
      'table': {
        'cols': [...dataCols],
        'rows': [...dataRows],
      },
    };

    return JSON.stringify(json);
  }

  showMenu(x, y) {
    this.menu.style.left = `${x}px`;
    this.menu.style.top = `${y}px`;
    this.menu.classList.add('active');
  }

  showContextMenu(el, x, y) {
    const isDates = el.classList.contains('table-contextmenu--dates');
    if (isDates) {
      const inputs = el.querySelectorAll('li input');
      const target = this.target;
      const dates = target.querySelectorAll('[data-value]');

      const inputsArr = [];
      inputs.forEach((element) => {
        inputsArr.push(element.value);
        element.checked = false;
      });

      dates.forEach((date) => {
        const index = inputsArr.indexOf(date.dataset.value, 0);
        inputs[index].checked = true;
      });
    }

    el.style.left = `${x}px`;
    el.style.top = `${y}px`;
    el.classList.add('active');
  }

  hideContextMenu(el) {
    if (el) {
      el.classList.remove('active');
      el.style.left = '';
      el.style.top = '';
    }
  }

  showDropdown() {
    const target = this.target.closest('.table__menu');

    if (target) {
      this.target.classList.add('active');
    }
  }

  hideDropdown() {
    const dropdowns = document.querySelectorAll('.table__menu');
    dropdowns.forEach((element) => {
      element.classList.remove('active');
    });
  }

  updateContextmenu(el) {
    el.style.left = '0';
    el.style.top = '0';

    this.tdCoords = {
      x: this.target.closest('.table__menu').getBoundingClientRect().left + this.wrapper.offsetLeft,
      y: this.target.closest('.table__menu').getBoundingClientRect().bottom + pageYOffset + 1,
    };

    if (this.context) {
      if (this.tdCoords.x + this.context.clientWidth > window.innerWidth) {
        const offset = this.tdCoords.x + this.context.clientWidth - window.innerWidth + 2;
        this.tdCoords.x = this.tdCoords.x - offset;
      }
    }

    el.style.left = `${this.tdCoords.x}px`;
    el.style.top = `${this.tdCoords.y}px`;
  }

  deleteContextmenu(target) {
    target.addEventListener('contextmenu', (e) => {
      e.preventDefault();
    });
  }

  initListeners() {

    // identify an element to observe
    /*const elementToObserve = document.querySelector(".js-table tbody");
    console.log(elementToObserve);

    // create a new instance of `MutationObserver` named `observer`,
    // passing it a callback function
    const observer = new MutationObserver(function() {
      console.log('callback that runs when observer is triggered');
    });

    // call `observe()` on that MutationObserver instance,
    // passing it the element to observe, and the options object
    observer.observe(elementToObserve, {subtree: true, childList: true});*/

    document.querySelector('.js-table').addEventListener('table:rendered', (e) => {
      this.calculateResult();
    });

    window.addEventListener('mouseup', (e) => {
      const target = e.target;

      if (target.closest('tr')) {
        if (target.closest('tr').classList.contains('js-context-menu')) {
          this.target = target.closest('td');
          this.row = this.target.closest('tr');

          if (e.button === 2) {
            let x = e.pageX;
            const y = e.pageY;

            if (x + this.menu.clientWidth > window.innerWidth) {
              x = x - this.menu.clientWidth;
            }

            if (target.closest('td').getAttribute('contenteditable') === 'false') {
              this.menu.classList.add('readonly');
            } else {
              this.menu.classList.remove('readonly');
            }

            this.showMenu(x, y);
          }

          if (e.button === 0 && this.target.classList.contains('active')) {
            const contextmenu = document.querySelectorAll('.table-contextmenu');
            contextmenu.forEach((element) => {
              this.hideContextMenu(element);
            });
            this.target.classList.remove('active');
          } else {
            if (this.target && this.target.getAttribute('data-context') && e.button === 0) {
              const tableItems = document.querySelector('.js-table-items');
              const td = this.target.closest('.table__menu');
              this.context = tableItems.querySelector(`[data-context="${td.dataset.context}"]`);

              const menu = document.querySelectorAll('.table__menu');
              menu.forEach((element) => {
                element.classList.remove('active');
              });

              this.tdCoords = {
                x: this.target.closest('.table__menu').getBoundingClientRect().left + this.wrapper.offsetLeft,
                y: this.target.closest('.table__menu').getBoundingClientRect().bottom + pageYOffset + 1,
              };

              if (this.tdCoords.x + this.context.clientWidth > window.innerWidth) {
                const offset = this.tdCoords.x + this.context.clientWidth - window.innerWidth + 2;
                this.tdCoords.x = this.tdCoords.x - offset;
              }

              const contextmenu = document.querySelectorAll('.table-contextmenu');
              contextmenu.forEach((element) => {
                this.hideContextMenu(element);
              });

              this.showContextMenu(this.context, this.tdCoords.x, this.tdCoords.y);
              td.classList.add('active');
            }
          }
        }
      }
    });

    window.addEventListener('click', (e) => {
      const target = e.target;
      const contextmenuDates = target.closest('.table-contextmenu--dates');

      if (target.closest('.table-contextmenu')) {
        const menu = target.closest('.table-contextmenu');
        const li = target.closest('li');
        const dates = menu.classList.contains('table-contextmenu--dates');

        if (dates) {
          if (target.closest('li')) {
            const regexpMonth = /[a-zA-Z-а-яёА-ЯЁ]+/;
            const regexpReplace = /\s[a-zA-Z-а-яёА-ЯЁ]+/;
            const regexpNum = /\d+/;

            const input = target.closest('.checkbox-wrapper').querySelector('input');
            const monthContainer = this.target.querySelector(`[data-month="${input.value.match(regexpMonth)}"]`);
            const span = this.target.querySelector('span');

            const item = document.createElement('span');
            item.textContent = input.value;
            item.dataset.value = input.value;

            if (input.checked) {
              if (monthContainer) {
                let items = span.querySelectorAll(`[data-month="${input.value.match(regexpMonth)}"] span`);
                const itemDate = Number(item.dataset.value.match(regexpNum)[0]);
                const datesArr = [];
                datesArr.push(itemDate);

                items.forEach((element) => {
                  const date = Number(element.dataset.value.match(regexpNum)[0]);
                  datesArr.push(date);
                });

                const sortArr = datesArr.sort(function (a, b) {
                  return a - b;
                });

                const index = sortArr.indexOf(itemDate);
                const spans = monthContainer.querySelectorAll('span');

                if (spans[index]) {
                  monthContainer.insertBefore(item, spans[index]);
                } else {
                  monthContainer.appendChild(item);
                }

                setTimeout(() => {
                  this.updateContextmenu(this.context);
                }, 1);
              } else {
                const newItem = document.createElement('span');
                newItem.dataset.month = item.dataset.value.match(regexpMonth);
                span.appendChild(newItem);
                newItem.appendChild(item);
                this.updateContextmenu(this.context);
              }
            } else {
              let removeItems = span.querySelectorAll(`[data-month="${input.value.match(regexpMonth)}"] span`);
              removeItems.forEach((removeItem) => {
                if (input.value === removeItem.dataset.value) {
                  removeItem.parentElement.removeChild(removeItem);
                }
              });

              removeItems = span.querySelectorAll(`[data-month="${input.value.match(regexpMonth)}"] span`);
              if (removeItems.length === 1) {
                removeItems[0].textContent = removeItems[0].dataset.value;
              }

              this.updateContextmenu(this.context);
            }

            const months = this.target.querySelectorAll(`[data-month="${input.value.match(regexpMonth)}"] span`);
            for (let i = 0; i < months.length - 1; i++) {
              const replacedMonth = months[i].textContent.replace(regexpReplace, ', ');
              months[i].textContent = replacedMonth;
            }

            if (months[months.length - 1]) {
              months[months.length - 1].textContent = months[months.length - 1].dataset.value;
            }
          }
        } else {
          const span = this.target.querySelector('span');
          if (target.closest('li')) {
            span.textContent = li.textContent;
            span.dataset.itemid = li.id;
            span.dataset.bid = '';
            const price = li.dataset.price;
            const targetRow = this.target.closest('.js-context-menu');
            targetRow.querySelector('.js-price').textContent = (new Intl.NumberFormat()).format(price);
            targetRow.querySelector('.js-count').textContent = 1;
            this.calculateResult();
            // return here
            this.hideContextMenu(this.context);
          }
        }
      }

      if (!target.closest('.table-menu')) {
        this.hideMenu();
      }

      if (!target.closest('.table__menu')) {
        this.hideDropdown();
      }

      if (contextmenuDates) {
        this.target.classList.add('active');
      }

      if (!target.closest('.table-contextmenu') && !target.closest('.table__menu')) {
        const contextmenu = document.querySelectorAll('.table-contextmenu');

        contextmenu.forEach((element) => {
          this.hideContextMenu(element);
        });
      }
    });

    const buttonAddService = document.querySelector('.form-footer__button--service');
    buttonAddService.addEventListener('click', () => {
      const table = document.querySelector('.js-table');
      const tableRows = table.querySelectorAll('.js-context-menu');
      this.row = tableRows[tableRows.length - 1];
      this.addRow();
    });

    const tableInstance = this;

    /*window.addEventListener('beforeunload', function (e) {
      if (tableInstance.synced) {
        // the absence of a returnValue property on the event will guarantee the browser unload happens
        delete e['returnValue'];
      } else {
        // Cancel the event
        e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
        // Chrome requires returnValue to be set
        e.returnValue = '';
      }

    });*/
    /*window.onbeforeunload = function() {
      console.log(tableInstance);

    };*/
  }

  dataSync() {
    setInterval(() => {
      const url = document.querySelector('.js-table').dataset.tableSync;
      const Request = new XMLHttpRequest();
      Request.open('POST', url, true);
      Request.send(this.tableToJson());
    }, 30000);
  }

  removeRow() {
    const table = document.querySelector('.js-table');
    const rowLength = table.querySelectorAll('.js-context-menu').length;

    if (rowLength === 1) {
      return;
    }

    this.row.parentNode.removeChild(this.row);
    this.hideMenu();
    this.inputs = this.table.querySelectorAll('.js-context-menu');
    this.calculateResult();
  }

  addRow() {
    const rowPattern = this.row.cloneNode(true);
    const td = rowPattern.querySelectorAll('td');

    td.forEach((element) => {
      if (element.classList.contains('table__menu')) {
        element.querySelector('span').innerText = '';
        return;
      }
      element.innerText = '';
    });

    const insertElement = this.row.parentElement.insertBefore(rowPattern, this.row.nextSibling);

    setTimeout(() => {
      this.deleteContextmenu(insertElement);
    }, 10);

    this.hideMenu();

    this.initCalculate(rowPattern);
    this.touch();
  }

  duplicateRow() {
    const rowPattern = this.row.cloneNode(true);
    rowPattern.querySelector('.table__service.table__menu span').dataset.bid = '';
    const insertElement = this.row.parentElement.insertBefore(rowPattern, this.row.nextSibling);

    this.deleteContextmenu(insertElement);


    this.hideMenu();
    this.inputs = this.table.querySelectorAll('.js-context-menu');
    this.calculateResult();
  }

  paste() {
    navigator.clipboard.readText().then((clipText) => {
      this.target.textContent = clipText;
    });
    this.hideMenu();
  }

  copy() {
    if (this.target.classList.contains('table__menu')) {
      const text = this.target.querySelector('span').textContent;
      navigator.clipboard.writeText(text);
    } else {
      navigator.clipboard.writeText(this.target.textContent);
    }

    this.hideMenu();
  }

  cut() {
    navigator.clipboard.writeText(this.target.textContent);
    this.target.textContent = '';
    this.hideMenu();
  }

  getAction() {
    this.menu.addEventListener('click', (e) => {
      if (e.target.closest('.table-menu__item')) {
        const target = e.target.closest('.table-menu__item').dataset.action;

        switch (target) {
          case 'cut':
            this.cut();
            break;
          case 'copy':
            this.copy();
            break;
          case 'paste':
            this.paste();
            break;
          case 'remove':
            this.removeRow();
            break;
          case 'add':
            this.addRow();
            break;
          case 'duplicate':
            this.duplicateRow();
            break;
        }

        this.touch();
      }
    });
  }

  initCalculate(item) {
    const price = item.querySelector('.js-price');
    const count = item.querySelector('.js-count');
    const rowTotal = item.querySelector('.js-total');

    this.inputs = this.table.querySelectorAll('.js-context-menu');

    item.addEventListener('input', () => {
      this.calculate(rowTotal, count, price);
    });
  }

  calculate(rowTotal, count, price) {
    const quantity = this.table.querySelector('.js-quantity-all');
    const total = this.table.querySelector('.js-total-all');

    const countAll = this.table.querySelectorAll('.js-count');
    const priceAll = this.table.querySelectorAll('.js-total');
    const numberFormat = new Intl.NumberFormat();

    let countValue = 0;
    let priceValue = 0;

    if (price.textContent && count.textContent) {
      rowTotal.textContent = numberFormat.format(parseNum(price.textContent) * parseNum(count.textContent));
    }

    countAll.forEach((element) => {
      if (element.textContent) {
        countValue = countValue + parseNum(element.textContent);
      }
    });

    priceAll.forEach((element) => {
      if (element.textContent) {
        priceValue = priceValue + parseNum(element.textContent);
      }
    });

    if (!isNaN(countValue)) {
      quantity.textContent = numberFormat.format(countValue);
    }

    if (!isNaN(priceValue)) {
      total.textContent = numberFormat.format(priceValue);
    }
  }

  calculateResult() {
    this.table = document.querySelector('.js-table');
    this.inputs = this.table.querySelectorAll('.js-context-menu');

    this.inputs.forEach((input) => {
      const price = input.querySelector('.js-price');
      const count = input.querySelector('.js-count');
      const rowTotal = input.querySelector('.js-total');

      input.addEventListener('input', () => {
        this.calculate(rowTotal, count, price);
        this.touch();
      });

      this.calculate(rowTotal, count, price);
    });
  }

  initContextMenu() {
    const menu = document.querySelector('.table-contextmenu');
    const paddings = parseFloat(getComputedStyle(menu).paddingTop) + parseFloat(getComputedStyle(menu).paddingBottom);
    const menuItems = menu.querySelectorAll('li');
    const border = 2;
    let height = paddings + border;
    if (menuItems.length > 3) {
      height = paddings + border - 12;
    } else {
      height = paddings + border;
    }

    for (let i = 0; i < 4; i++) {
      const element = menuItems[i];

      if (element) {
        height = height + element.clientHeight + parseFloat(getComputedStyle(element).marginBottom);
      }
    }

    menu.style.maxHeight = `${height}px`;
  }

  async init() {
    this.initListeners();
    this.getAction();

    this.createRequest();
    await this.delay(600);
    this.checkBrowser();
    await this.delay(100);
    //this.dataSync();
    this.addToCart();
    this.calculateResult();
  }
}

export {renderTable};
