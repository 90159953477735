import moment from "moment";

const successPopup = document.querySelector('.notification.notification--success');

const closeSuccessPopup = (el) => {
  el.classList.add('visually-hidden');
  el.classList.remove('active');
};

const openSuccessPopup = (el) => {
  el.classList.remove('visually-hidden');
  el.classList.add('active');
};

const showSuccess = (text = { tooltip: 'Заявка отправлена', message: 'Модератор свяжется с вами в ближайшее время'}, success = true) => {
  if (successPopup) {
    const TIMEOUT = +successPopup.closest('.notifications').getAttribute('data-timeout');
    const closeSuccessPopupButton = successPopup.querySelector('.notification__close-btn');
    const time = moment().format('H:mm');
    const type = success;
    if (type) {
      successPopup.classList.add('notification--success');
      successPopup.classList.remove( 'notification--error');
    } else {
      successPopup.classList.add('notification--error');
      successPopup.classList.remove( 'notification--success');
    }
    successPopup.querySelector('.notification__time').innerHTML = time;
    successPopup.querySelector('.notification__message-text').innerHTML = text.message;
    successPopup.querySelector('.notification__tooltip').innerHTML= text.tooltip;

    openSuccessPopup(successPopup);

    setTimeout(() => {
      closeSuccessPopup(successPopup);
    }, TIMEOUT);

    closeSuccessPopupButton.addEventListener('click', () => {
      closeSuccessPopup(successPopup);
    });
  }
};

export {showSuccess};
