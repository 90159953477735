import MoveTo from '../vendor/move-to';

const scroll = () => {
  const easeFunctions = {
    easeInQuad(t, b, c, d) {
      t /= d;
      return c * t * t + b;
    },

    easeOutQuad(t, b, c, d) {
      t /= d;
      return -c * t * (t - 2) + b;
    },
  };

  const moveTo = new MoveTo({
    easing: 'easeInQuad',
  }, easeFunctions);

  const triggers = document.querySelectorAll('.js-trigger');

  triggers.forEach((trigger) => {
    moveTo.registerTrigger(trigger);
  });
};

export {scroll};
