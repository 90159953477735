const initFixedButton = () => {
  const containers = document.querySelectorAll('.fixed-button-container');

  if (!containers) {
    return;
  }

  containers.forEach((container) => {
    const button = container.querySelector('.fixed-button');

    const initButton = () => {
      if (!(container.getBoundingClientRect().top + button.clientHeight > window.innerHeight) && !(container.getBoundingClientRect().top + container.clientHeight < window.innerHeight)) {
        button.classList.add('active');
        button.style.position = 'fixed';
        return;
      }
      if (container.getBoundingClientRect().top + button.clientHeight > window.innerHeight) {
        button.style.position = 'absolute';
        button.classList.remove('active');
      }
      if (container.getBoundingClientRect().top + container.clientHeight < window.innerHeight) {
        button.style.position = 'absolute';
        button.classList.remove('active');
      }
    };

    initButton();
    window.addEventListener('scroll', initButton);
    window.addEventListener('resize', initButton);
  });
};

export {initFixedButton};
