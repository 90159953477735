const checkboxGroups = document.querySelectorAll('.js-checkbox-group');

const checkAllcheckboxes = (group) => {
  const checkboxes = group.querySelectorAll('.js-checkbox-group__checkbox');
  const btnSelect = group.querySelector('.js-checkbox-group__btn-select');
  const btnDismiss = group.querySelector('.js-checkbox-group__btn-dismiss');

  btnSelect.style.display = 'none';
  btnDismiss.style.display = 'block';

  checkboxes.forEach((checkbox) => {
    checkbox.checked = true;
  });
};

const uncheckAllcheckboxes = (group) => {
  const checkboxes = group.querySelectorAll('.js-checkbox-group__checkbox');
  const btnSelect = group.querySelector('.js-checkbox-group__btn-select');
  const btnDismiss = group.querySelector('.js-checkbox-group__btn-dismiss');

  btnSelect.style.display = 'block';
  btnDismiss.style.display = 'none';

  checkboxes.forEach((checkbox) => {
    checkbox.checked = false;
  });
};

const correctIsCheckedAllStatus = (group) => {
  const checkboxes = group.querySelectorAll('.js-checkbox-group__checkbox');
  const btnSelect = group.querySelector('.js-checkbox-group__btn-select');
  const btnDismiss = group.querySelector('.js-checkbox-group__btn-dismiss');

  for (let i = 0; i < checkboxes.length; i++) {
    if (!checkboxes[i].checked) {
      group.dataset.isCheckedAll = '';
      btnSelect.style.display = 'block';
      btnDismiss.style.display = 'none';
      return;
    }
  }

  group.dataset.isCheckedAll = 'checked';
  btnSelect.style.display = 'none';
  btnDismiss.style.display = 'block';
};

const initSelectAllOptions = () => {
  if (checkboxGroups.length) {
    document.addEventListener('click', (evt) => {
      const checkboxGroup = evt.target.closest('.js-checkbox-group');

      if (checkboxGroup) {
        const selectAllBtn = evt.target.closest('.js-checkbox-group__btn');

        if (selectAllBtn) {
          evt.preventDefault();

          if (checkboxGroup.dataset.isCheckedAll === 'checked') {
            uncheckAllcheckboxes(checkboxGroup);
            checkboxGroup.dataset.isCheckedAll = '';
          } else {
            checkAllcheckboxes(checkboxGroup);
            checkboxGroup.dataset.isCheckedAll = 'checked';
          }
        }

        if (evt.target.closest('.js-checkbox-group__checkbox')) {
          correctIsCheckedAllStatus(checkboxGroup);
        }
      }
    });
  }
};

export {initSelectAllOptions};
