const initTitleButton = () => {
  const button = document.querySelector('[data-price-button]');
  const notification = document.querySelector('.stand-annotation');

  if (!button || !notification) {
    return;
  }

  const buttonWrapper = button.closest('.category-add__title-wrapper');

  let coord = notification.getBoundingClientRect().left - buttonWrapper.getBoundingClientRect().left;
  button.style.left = `${coord}px`;

  window.addEventListener('resize', () => {
    coord = notification.getBoundingClientRect().left - buttonWrapper.getBoundingClientRect().left;
    button.style.left = `${coord}px`;
  });
};

export {initTitleButton};
