const setActiveElement = () => {
  const containers = document.querySelectorAll('.setActiveContainer');

  if (!containers) {
    return;
  }

  containers.forEach((container) => {
    container.addEventListener('click', (e) => {
      const target = e.target;
      const element = target.closest('.setActiveElement');
      const items = container.querySelectorAll('.setActiveElement');
      if (element) {
        items.forEach((item) => {
          item.classList.remove('active');
        });
        element.classList.add('active');
      }
    });
  });
};

export {setActiveElement};
