import {ieFix} from './utils/ie-fix';
import {iosVhFix} from './utils/ios-vh-fix';

import {initModals} from './modules/init-modals';
import {initAccordion} from './modules/init-accordion';
import {initTabs} from './modules/tabs';
import {initSelects} from './modules/custom-select';
import {orderCounter} from './modules/order-counter';
import {initLabelMoving} from './modules/init-label-moving';
import {initPhoneMask} from './modules/phone-mask';
import {initFormValidate, validateFile} from './modules/init-form-validate';
import {initEventCardPopups} from './modules/init-event-card-popup';
import {scroll} from './modules/scroll';
import {initAside} from './modules/aside';
import {colorSelectionInit} from './modules/lamination-colors';
import {initCheckboxList} from './modules/checkbox-list';
import {initSelectAllOptions} from './modules/init-select-all-options';
import {initModalStandCardCheckboxes} from './modules/init-stand-checkboxes';
import {initMessagesOptions} from './modules/init-messages-options';
import {initFilter} from './modules/filter';
import {initDropdown} from './modules/dropdown';
import {initCart} from './modules/init-cart';
import {initTarifIncreasePopup} from './modules/init-tarifs-increase-popup';
import {initFixedButton} from './modules/fixed-button';
import {customTabs} from './modules/custom-tabs';
import {notifications} from './modules/notification';
import {initNumInputs} from './modules/init-num-inputs';
import {initUpload} from './modules/file-upload';
import {showSuccess} from './modules/show-success';
import {initAccountOrderCounter} from './modules/init-account-order-counter';
import {dragFile} from './modules/drag-file';
import {setActiveElement} from './modules/setActiveElement';
import {initTable} from './modules/init-table';
import {initFileValidate, removeClasses} from './modules/init-file-validate';
import {initFirefoxNotification} from './modules/firefox-notification';
import {initTitleButton} from './modules/init-title-button';
import {initHeader} from './modules/header';
import {initJsLinks} from './modules/init-js-links';
import {initCartActions} from './modules/cart-actions';


window.addEventListener('DOMContentLoaded', () => {

  // Utils
  // ---------------------------------

  ieFix();
  iosVhFix();

  // Modules
  // ---------------------------------

  initModals();
  initAccordion();
  initTabs();
  // initPhoneMask();
  initSelects();
  orderCounter();
  initFormValidate();
  initEventCardPopups();
  scroll();
  initAside();
  colorSelectionInit();
  initCheckboxList();
  initSelectAllOptions();
  initModalStandCardCheckboxes();
  initMessagesOptions();
  initFilter();
  initDropdown();
  initCart();
  initTarifIncreasePopup();
  initFixedButton();
  customTabs();
  //notifications();
  initNumInputs();
  initUpload();
  initAccountOrderCounter();
  dragFile();
  setActiveElement();
  initFileValidate();
  removeClasses();
  initFirefoxNotification();
  initTitleButton();
  initHeader();
  initJsLinks();
  initCartActions();

  window.addEventListener('load', () => {
    initLabelMoving();
    initTable();
  });
});
