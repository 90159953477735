const initHeader = () => {
  const header = document.querySelector('.header');
  const headerHeight = header.clientHeight;

  window.addEventListener('wheel', (e) => {
    const scrollDirection = e.deltaY > 0 ? 'down' : 'up';
    const currentScroll = window.pageYOffset;

    if (currentScroll > headerHeight) {
      if (scrollDirection === 'down') {
        header.classList.add('hidden');
      }

      if (scrollDirection === 'up') {
        header.classList.remove('hidden');
      }
    }
  });
};

export {initHeader};
