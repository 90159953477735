const aside = document.querySelectorAll('.aside');
let content;

const animateAside = () => {
  if (!aside) {
    return;
  }

  aside.forEach((element) => {
    const offset = window.pageYOffset;
    const contentHeight = content.clientHeight;
    const topFix = element.getBoundingClientRect().top + window.pageYOffset;
    const contentBottom = element.getBoundingClientRect().bottom - contentHeight;
    element.style.minHeight = contentHeight + 'px';

    if (offset > topFix) {
      if (contentBottom < 0) {
        content.setAttribute('style', 'position: absolute; bottom: 0; top: unset;');
      } else {
        content.setAttribute('style', 'position: fixed; width: ' + content.clientWidth + 'px');
      }
    } else {
      content.removeAttribute('style');
    }

  });
};

const initAside = () => {
  if (!aside) {
    return;
  }

  aside.forEach((element) => {
    content = element.querySelector('.aside__content');

    animateAside();
    window.addEventListener('resize', animateAside);
    window.addEventListener('scroll', animateAside);
  });
};

export {initAside};
