import axios from "axios";
import {showSuccess} from "../modules/show-success";
import {resetForm} from "../modules/init-form-validate";
import {showNotifiction} from "../modules/notification";
import {closeModal} from "../utils/modal";
import {orderCounter} from "../modules/order-counter";
import {initJsLinks} from "../modules/init-js-links";
import {calculateOrder} from "../modules/order-counter";

const ajaxBase = '/remote/router.php';
const ajaxEndpoints = {
    'AUTH_MAIN': ajaxBase + '?action=auth'
};

const sendFormRequest = (form, isSuccess = true, noReset = true, close = true) => {
    const data = new FormData(form);
    const action = form.action;
    const method = form.method;
    const submitBtn = form.querySelector('[type="submit"]');
    if (submitBtn) {
        submitBtn.disabled = true;
    }

    const modal = form.closest('.modal');
    const standCards = form.querySelectorAll('.stand-card');
    const catalogInfo = form.closest('.catalog-info');
    const product = document.querySelector('.notification--product');


    let cardObj = false;

    if (standCards) {
        standCards.forEach((standCard) => {
            const checkedInput = standCard.querySelector('[type="checkbox"]:checked');
            if (checkedInput) {
                cardObj = {
                    productName: standCard.querySelector('.stand-card__stand-type').textContent,
                    productPrice: standCard.querySelector('.stand-card__base-price').textContent,
                    value: 1,
                };
            }
        });

    } else if (modal) {
        cardObj = {
            productName: modal.dataset.product
        }
    } else if (catalogInfo) {
        cardObj = {
            productName: catalogInfo.dataset.product
        }
    }

    const response = axios({
        method: method,
        url: action,
        data: data
    }).then((response) => {
        const data = response.data;

        if (!noReset) {
            resetForm(form);
        }

        if (isSuccess) {
            showSuccess(data.text, data.status);
        }

        if (modal && close) {
            closeModal(modal);
        }
        if (cardObj) {
            showNotifiction(product, cardObj, data.message);
        }

        if (data.redirect_url) {
            setTimeout(function () {
                location.href = data.redirect_url;
            }, 1000);
        }

        if (data.reload) {
            setTimeout(function () {
                location.reload();
            }, 1000);
        }
        if (typeof data.data.count !== 'undefined') {
            const mainCounter = document.querySelector('.main-nav__counter');
            if (mainCounter) {
                mainCounter.textContent = data.data.count;
            }

        }

        const html = data.data.html;
        if (html) {
            let tmpWrap = document.createElement('div');
            tmpWrap.innerHTML= html;
            const cartContent = tmpWrap.querySelector('.cart__content');
            document.querySelector('.cart .cart__content').replaceWith(cartContent);
            orderCounter(document.querySelector('.cart'));
            initJsLinks();
        }

        const stand_html = data.data.stand_html;
        if (stand_html) {
            location.reload();
            /*let tmpWrap = document.createElement('div');
            tmpWrap.innerHTML= stand_html;
            const orderWrapper = document.querySelector('.section-grid--order');
            if (orderWrapper) {
              const standCard = orderWrapper.querySelector('.stand-card');
              standCard.replaceWith(tmpWrap.querySelector('.stand-card'));
              calculateOrder(orderWrapper, data.data.prices_list);
              closeModal(document.querySelector('.modal--upgrade-stand'));
            }*/
        }

        if (submitBtn) {
            submitBtn.disabled = false;
        }

    });
};

const sendCartRequest = (input, product) => {
    product = typeof product !== 'undefined' ? product : false;
    const cardForm = input.form;
    const hiddenActionVar = cardForm.querySelector('.hidden-action-var');
    const currentValue = input.value;
    if (currentValue <= 0) {
        hiddenActionVar.value = 'remove-basket';
    } else {
        hiddenActionVar.value = 'update-basket-quantity';
    }

    const data = new FormData(cardForm);
    const action = cardForm.action;
    const method = cardForm.method;

    axios({
        method: method,
        url: action,
        data: data
    }).then((response) => {
        const data = response.data;
        const actionVar = data.action;
        switch (actionVar) {
            case 'put-basket':
                cardForm.actionVar.value = 'update-basket-quantity';
                cardForm.bid.value = data.item.id;
                if (product) {
                    showNotifiction(product);
                }

                break;
            case 'update-basket-quantity':
                showNotifiction(product);
                break;
            case 'remove-basket':
                cardForm.actionVar.value = 'put-basket';
                cardForm.bid.value = null;
                break;
        }



    });
};

export {sendFormRequest, sendCartRequest};
