const renderstandActions = (list, actions) => {
  list.textContent = '';

  actions.forEach((action) => {
    const standAction = document.createElement('LI');
    const popupLink = document.createElement('A');

    popupLink.classList.add('event-card__popup-link');
    popupLink.href = action.href;
    popupLink.textContent = action.text;

    standAction.classList.add('event-card__stand-action');
    standAction.append(popupLink);

    list.append(standAction);
  });
};

const closePopup = () => {
  const currentPopup = document.querySelector('.event-card__popup--opened');

  if (currentPopup) {
    currentPopup.classList.remove('event-card__popup--opened');
  }
};

const onEventCardActionsClick = (evt) => {
  const eventCardActionBtn = evt.target.closest('.event-card__actions');
  const currentEventCard = evt.target.closest('.event-card');

  if (eventCardActionBtn) {
    evt.preventDefault();
    const eventCardPopup = currentEventCard.querySelector('.event-card__popup');

    if (!eventCardPopup.classList.contains('event-card__popup--opened')) {
      closePopup();
    }

    eventCardPopup.classList.toggle('event-card__popup--opened');
  } else {
    if (!evt.target.closest('.event-card__popup')) {
      closePopup();
    }
  }
};

const onEscPress = (evt) => {
  const isEscKey = evt.key === 'Escape' || evt.key === 'Esc';

  if (isEscKey) {
    evt.preventDefault();

    closePopup();
  }
};

const onSelectedStandClick = (evt) => {
  const selectedStand = evt.target.closest('.event-card__popup-selected-stand');

  if (selectedStand) {
    evt.preventDefault();

    const popup = selectedStand.closest('.event-card__popup');

    popup.classList.toggle('event-card__popup--opened-actions');
  }
};

const onStandClick = (evt) => {
  const stand = evt.target.closest('.event-card__popup-stand .event-card__popup-link');

  if (stand) {
    evt.preventDefault();

    const ACTIONS = JSON.parse(stand.dataset.standmenu);

    const popup = stand.closest('.event-card__popup');
    const selectedStand = popup.querySelector('.event-card__popup-selected-stand span');
    const standActions = popup.querySelector('.event-card__stand-actions');

    selectedStand.textContent = stand.textContent;

    renderstandActions(standActions, ACTIONS);
  }
};

const initEventCardPopups = () => {
  const eventsList = document.querySelector('.events__list');

  if (eventsList) {
    const cards = document.querySelectorAll('.event-card');

    cards.forEach((card) => {
      const standActions = card.querySelector('.event-card__stand-actions');
      const currentStand = card.querySelector('.event-card__popup-selected-stand span');
      const stand = card.querySelector(`[data-stand="${currentStand.textContent}"]`);

      if (stand) {
        const ACTIONS = JSON.parse(stand.dataset.standmenu);

        renderstandActions(standActions, ACTIONS);
      }
    });

    document.addEventListener('click', onEventCardActionsClick);
    document.addEventListener('keydown', onEscPress);
    eventsList.addEventListener('click', onSelectedStandClick);
    eventsList.addEventListener('click', onStandClick);
  }
};

export {initEventCardPopups};
