const colorSelection = document.querySelectorAll('.palette');

const colorSelectionInit = () => {
  colorSelection.forEach((element) => {
    const onColorItemChange = (evt) => {
      if (evt.target.closest('.lamination-color input')) {
        const colorName = element.querySelector('.palette__color-name');
        const colorNameOutput = colorName.querySelector('output');
        const colorSrgb = element.querySelector('.palette__color-srgb');
        const colorSrgbOutput = colorSrgb.querySelector('output');
        const colorInput = evt.target.closest('.lamination-color input');

        if (colorInput.value && colorInput.value !== 'transparent') {
          colorNameOutput.textContent = ` — ${colorInput.value}`;
          colorName.dataset.colorName = colorInput.value;
          element.dataset.isSelected = 'selected';
        } else {
          colorNameOutput.textContent = '';
          element.dataset.isSelected = '';
          colorName.dataset.colorName = '';
        }

        if (colorInput.dataset.rgb) {
          colorSrgbOutput.textContent = `sRGB: ${colorInput.dataset.rgb}`;
          colorSrgb.dataset.colorSrgb = colorInput.dataset.rgb;
        } else {
          colorSrgbOutput.textContent = '';
          colorSrgb.dataset.colorSrgb = '';
        }
      }
    };

    const onColorItemMouseover = (evt) => {
      if (evt.target.closest('label')) {
        const colorName = element.querySelector('.palette__color-name');
        const colorNameOutput = colorName.querySelector('output');
        const colorSrgb = element.querySelector('.palette__color-srgb');
        const colorSrgbOutput = colorSrgb.querySelector('output');
        const colorBox = (evt.target.closest('.lamination-color'));
        const colorInput = colorBox.querySelector('input');

        if (colorInput.value && colorInput.value !== 'transparent') {
          colorNameOutput.textContent = ` — ${colorInput.value}`;
        } else {
          colorNameOutput.textContent = '';
        }

        if (colorInput.dataset.rgb) {
          colorSrgbOutput.textContent = `sRGB: ${colorInput.dataset.rgb}`;
        } else {
          colorSrgbOutput.textContent = '';
        }
      }
    };

    const onColorItemMouseout = (evt) => {
      if (evt.target.closest('label')) {
        const colorName = element.querySelector('.palette__color-name');
        const colorNameOutput = colorName.querySelector('output');
        const colorSrgb = element.querySelector('.palette__color-srgb');
        const colorSrgbOutput = colorSrgb.querySelector('output');

        if (colorName.dataset.colorName) {
          colorNameOutput.textContent = ` — ${colorName.dataset.colorName}`;
          colorSrgbOutput.textContent = `sRGB: ${colorSrgb.dataset.colorSrgb}`;
        } else {
          colorNameOutput.textContent = '';
          colorSrgbOutput.textContent = '';
        }
      }
    };

    if (element) {
      element.addEventListener('change', onColorItemChange);
      element.addEventListener('mouseover', onColorItemMouseover);
      element.addEventListener('mouseout', onColorItemMouseout);
    }
  });
};


export {colorSelectionInit};
