const initFilter = () => {
  const filter = document.querySelectorAll('.filter-js');

  if (!filter) {
    return;
  }

  filter.forEach((element) => {
    element.addEventListener('click', (e) => {
      e.preventDefault();
      const buttons = element.querySelectorAll('.section-navigation__item');
      const target = e.target;
      const filterName = target.dataset.sectionFilter;
      const sections = document.querySelectorAll('[data-section]');
      const selectedSections = document.querySelectorAll(`[data-section="${filterName}"]`);

      if (!filterName) {
        return;
      }

      buttons.forEach((button) => {
        button.classList.remove('active');
      });

      sections.forEach((section) => {
        if (filterName === 'all') {
          section.classList.remove('visually-hidden');
          target.closest('.section-navigation__item').classList.add('active');
          return;
        }

        section.classList.add('visually-hidden');
        target.closest('.section-navigation__item').classList.add('active');
      });

      selectedSections.forEach((selectedSection) => {
        selectedSection.classList.remove('visually-hidden');
      });
    });
  });
};
export {initFilter};
