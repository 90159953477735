const chatsList = document.querySelector('.account-chats__list');

const onControlBlockChangeClick = (evt) => {
  const switchBtn = evt.target.closest('.account-messages__control--change-mode');
  const controlBlockDefault = document.querySelector('.account-messages__control-block--default');
  const controlBlockEdit = document.querySelector('.account-messages__control-block--edit');
  const chatCards = chatsList.querySelectorAll('.chat-card');

  if (switchBtn) {
    evt.preventDefault();

    controlBlockDefault.classList.toggle('account-messages__control-block--shown');
    controlBlockEdit.classList.toggle('account-messages__control-block--shown');

    chatCards.forEach((chatCard) => {
      chatCard.classList.toggle('chat-card--select-mode');
    });
  }
};

const onSelectAllBtnClick = () => {
  setTimeout(() => {
    checkSelectedUnreadMessages();
    checkSelectedMessages();
  }, 100);
};

const onMarkAsReadBtnClick = () => {
  const markAsReadBtn = document.querySelector('.account-messages__control--mark-as-read');
  const chatCards = chatsList.querySelectorAll('.chat-card');

  chatCards.forEach((chatCard) => {
    const input = chatCard.querySelector('input');

    if (input.checked) {
      const messages = chatCard.querySelector('.chat-card__messages');

      if (messages) {
        messages.remove();
      }
    }
  });

  markAsReadBtn.disabled = true;
};

const onDeleteBtnClick = () => {
  const deleteBtn = document.querySelector('.account-messages__control--delete');
  const chatCards = chatsList.querySelectorAll('.chat-card');

  chatCards.forEach((chatCard) => {
    const input = chatCard.querySelector('input');

    if (input.checked) {
      chatCard.remove();
      checkSelectedUnreadMessages();
    }
  });

  deleteBtn.disabled = true;
};

const checkSelectedUnreadMessages = () => {
  const inputs = chatsList.querySelectorAll('.chat-card input');
  const markAsReadBtn = document.querySelector('.account-messages__control--mark-as-read');

  if (markAsReadBtn) {
    markAsReadBtn.disabled = true;
  }

  inputs.forEach((input) => {
    const chatCard = input.closest('.chat-card');
    const messages = chatCard.querySelector('.chat-card__messages');

    if (input.checked && messages) {
      if (markAsReadBtn) {
        markAsReadBtn.disabled = false;
        return;
      }
    }
  });
};

const checkSelectedMessages = () => {
  const inputs = chatsList.querySelectorAll('.chat-card input');
  const deleteBtn = document.querySelector('.account-messages__control--delete');

  deleteBtn.disabled = true;

  inputs.forEach((input) => {
    if (input.checked) {
      deleteBtn.disabled = false;
      return;
    }
  });
};

const onChatCardSelectionChange = (evt) => {
  if (evt.target.closest('.chat-card input')) {
    checkSelectedUnreadMessages();
    checkSelectedMessages();
  }
};

const initMessagesOptions = () => {
  if (chatsList) {
    const controlBlocks = document.querySelector('.account-messages__control-blocks');
    const markAsReadBtn = controlBlocks.querySelector('.account-messages__control--mark-as-read');
    const deleteBtn = controlBlocks.querySelector('.account-messages__control--delete');
    const selectAllBtn = controlBlocks.querySelector('.account-messages__control--select-all');

    controlBlocks.addEventListener('click', onControlBlockChangeClick);
    selectAllBtn.addEventListener('click', onSelectAllBtnClick);
    deleteBtn.addEventListener('click', onDeleteBtnClick);

    if (markAsReadBtn) {
      markAsReadBtn.addEventListener('click', onMarkAsReadBtnClick);
    }

    chatsList.addEventListener('change', onChatCardSelectionChange);
  }
};

export {initMessagesOptions};
