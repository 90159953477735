import {closeModal, openModal, setupModal} from '../utils/modal';
import axios from 'axios';

let targetsObject = {};
const confirmModal = document.querySelector('.modal--delete-service');

const initCartActions = () => {
    const container = document.querySelector('body');
    container.addEventListener('click', (e) => {
        const target = e.target;
        targetsObject.target = target;
        targetsObject.removeFromCart = target.closest('.js-remove-btn');
        targetsObject.removeConfirmBtn = target.closest('.js-confirm-delete');

        if (targetsObject.removeFromCart) {
            confirmModal.dataset.sid = targetsObject.removeFromCart.dataset.sid;
        }

        if (targetsObject.removeConfirmBtn) {
            const deleteSid  = confirmModal.dataset.sid;
            const jsRemoveBtn = document.querySelector('.js-remove-btn[data-sid="' + deleteSid + '"]');
            if (jsRemoveBtn) {
                const eid = jsRemoveBtn.dataset.eid;
                const code = jsRemoveBtn.dataset.code;
                const url = jsRemoveBtn.dataset.url;

                const data = {
                    actionVar: 'remove-basket',
                    eid: eid,
                    code: code,
                    sid: deleteSid
                };

                axios.post(url, data).then((response) => {
                    console.log(response.data);
                    location.reload();
                });
            }
        }
    });
};

export {initCartActions};
