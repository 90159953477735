const orderCounter = () => {
  const container = document.querySelectorAll('.order-counter');

  if (!container) {
    return;
  }

  container.forEach((counter) => {
    const minus = counter.querySelector('.order-counter__minus');
    const plus = counter.querySelector('.order-counter__plus');
    const input = counter.querySelector('input');
    const label = counter.querySelector('label');

    input.addEventListener('blur', () => {
      if (input.value === '' || input.value === '0') {
        counter.classList.remove('is-active');
      }
    });

    plus.addEventListener('click', () => {
      if (counter.classList.contains('settings')) {
        plus.classList.add('configure');
        return;
      }

      if (input.value === '0') {
        counter.classList.add('is-active');
      }

      input.value++;
      label.textContent = input.value;
    });

    minus.addEventListener('click', () => {
      input.value--;
      label.textContent = input.value;

      if (input.value === '0') {
        counter.classList.remove('is-active');
      }
    });
  });
};

export {orderCounter};
