const standAnnotation = document.querySelector('.stand-annotation');

const initTarifIncreasePopup = () => {
  if (standAnnotation) {
    const standAnnotationLink = standAnnotation.querySelector('.stand-annotation__link');
    const popup = standAnnotation.querySelector('.tarifs-increase');
    const overlay = standAnnotation.querySelector('.tarifs-increase__overlay');
    const popupContent = standAnnotation.querySelector('.tarifs-increase__popup');

    const openPopup = (x, y) => {
      popup.classList.add('tarifs-increase--opened');
      popupContent.style.top = `${y}px`;
      popupContent.style.left = `${x - popupContent.offsetWidth}px`;

      overlay.addEventListener('click', onOverlayClick);
      document.addEventListener('keydown', onEscPress);
      document.addEventListener('scroll', onScroll);
    };

    const closePopup = () => {
      popup.classList.remove('tarifs-increase--opened');

      overlay.removeEventListener('click', onOverlayClick);
      document.removeEventListener('keydown', onEscPress);
      document.removeEventListener('scroll', onScroll);
    };

    const onOverlayClick = () => {
      closePopup();
    };

    const onEscPress = (evt) => {
      const isEscKey = evt.key === 'Escape' || evt.key === 'Esc';

      if (isEscKey) {
        evt.preventDefault();

        closePopup();
      }
    };

    const onScroll = () => {
      closePopup();
    };

    const onStandAnnotationLinkClick = (evt) => {
      const bounds = standAnnotation.getBoundingClientRect();
      const x = evt.clientX - bounds.left - popupContent.offsetWidth;
      const y = evt.clientY - bounds.top;

      evt.preventDefault();

      openPopup(x, y);
    };

    standAnnotationLink.addEventListener('click', onStandAnnotationLinkClick);
  }
};

export {initTarifIncreasePopup};
