const initCheckboxList = () => {
  const checkboxList = document.querySelectorAll('.checkbox-list-js');

  if (!checkboxList) {
    return;
  }

  checkboxList.forEach((element) => {
    const checkboxItem = element.querySelectorAll('.checkbox-item-js');

    checkboxItem.forEach((item) => {
      const input = item.querySelector('input');

      input.addEventListener('change', () => {
        if (input.checked) {
          item.classList.add('active');
        } else {
          item.classList.remove('active');
        }
      });
    });
  });
};

export {initCheckboxList};
