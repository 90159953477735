import {showSuccess} from './show-success';
import {sendFormRequest} from "../utils/ajax";

const showError = (input) => {
  const inputState = input.getAttribute('aria-invalid');
  let errorMessage = input.closest('.js-form-item').querySelector('.js-form-error');
  if (inputState !== 'true') {
    input.setAttribute('aria-invalid', 'true');
    errorMessage.classList.add('visible');
  }
};

const hideError = (input) => {
  const inputState = input.getAttribute('aria-invalid');
  let errorMessage = input.closest('.js-form-item').querySelector('.js-form-error');
  if (inputState === 'true') {
    input.setAttribute('aria-invalid', 'false');
    errorMessage.classList.remove('visible');
  }
};

const validatePhone = (input) => {
  let flag = true;
  if (input.value.length > 3 && input.value.length <= 18) {
    hideError(input);
  } else {
    flag = false;
  }
  return flag;
};

const validateName = (input) => {
  let flag = true;
  if (input.value.length >= 2) {
    hideError(input);
  } else {
    flag = false;
  }
  return flag;
};

const validateSize = (input) => {
  const RE_SIZE = /^[1-9]\d{0,4}$/;
  let flag = true;
  if (RE_SIZE.test(input.value)) {
    hideError(input);
  } else {
    flag = false;
  }
  return flag;
};

const validateEmail = (input) => {
  let flag = true;
  const emailString = '^([A-z0-9_-]+.)*[A-z0-9_-]+@[A-z0-9_-]+(.[A-z0-9_-]+)*.[A-z]{2,6}$';
  const regEmail = new RegExp(emailString, '');
  if (regEmail.test(input.value)) {
    hideError(input);
  } else {
    flag = false;
  }
  return flag;
};

const validateCheckbox = (input) => {
  let flag = true;
  if (input.checked) {
    hideError(input);
  } else {
    flag = false;
  }
  return flag;
};

const validateSelect = (input) => {
  let flag = true;
  if (input.value) {
    hideError(input);
  } else {
    flag = false;
  }
  return flag;
};

const validatePassword = (input) => {
  let flag = true;
  if (input.value.length >= 6) {
    hideError(input);
  } else {
    flag = false;
  }
  return flag;
};

const confirmPassword = (input) => {
  let flag = true;
  const form = input.closest('form');
  const pwd = form.querySelector('[data-type="password"]');
  if (input.value === pwd.value) {
    hideError(input);
  } else {
    flag = false;
  }
  return flag;
};

const validateFile = (input) => {
  let flag = true;
  const inputParent = input.closest('.add-file');
  const inputLabel = inputParent.querySelector('.add-file__desc');
  if (input.value.length >= 4) {
    hideError(input);
    inputLabel.style.opacity = '1';
  } else {
    flag = false;
    inputLabel.style.opacity = '0';
  }
  return flag;
};


const validateInputs = (type, input) => {
  switch (type) {
    case 'tel':
      return validatePhone(input);
    case 'text':
      if (input.dataset.isSize) {
        return validateSize(input);
      }
      return validateName(input);
    case 'email':
      return validateEmail(input);
    case 'select':
      return validateSelect(input);
    case 'checkbox':
      return validateCheckbox(input);
    case 'textarea':
      return validateName(input);
    case 'password':
      return validatePassword(input);
    case 'confirm-pwd':
      return confirmPassword(input);
    case 'add-file':
      return validateFile(input);
    default:
      return false;
  }
};

const checkInputValidity = ({target}) => {
  const type = target.dataset.type;
  validateInputs(type, target);
};

const showInputsError = (inputs) => {
  let flag = true;
  let result = true;
  inputs.forEach((input) => {
    const type = input.dataset.type;
    flag = validateInputs(type, input);
    if (!flag) {
      result = flag;
      showError(input);
    }
  });
  return result;
};

const resetForm = (form) => {
  form.reset();
  const movedLabels = [...form.querySelectorAll('.moved')];
  const inputs = [...form.querySelectorAll('.js-form-item input')];

  if (inputs.length) {
    inputs.forEach((item) => {
      item.value = '';
    });
  }

  if (movedLabels.length) {
    movedLabels.forEach((label) => {
      label.classList.remove('moved');
    });
  }
};

const submitForm = (form) => {
  form.noValidate = true;
  const inputs = [...form.querySelectorAll('[aria-required="true"]')];
  const noCleaning = [...form.querySelectorAll('[data-clean="false"]')];
  const showSuccessPopup = [...form.querySelectorAll('[data-success="true"]')];
  const close = [...form.querySelectorAll('[data-close="true"]')];

  form.addEventListener('submit', (e) => {
    e.preventDefault();

    if (showInputsError(inputs)) {
      inputs.forEach((input) => {
        input.removeEventListener('input', checkInputValidity);
      });

      sendFormRequest(form, showSuccessPopup.length, noCleaning.length, close.length);
    } else {
      inputs.forEach((input) => {
        input.addEventListener('change', checkInputValidity);
        input.addEventListener('input', checkInputValidity);
      });
    }
  });
};

const initFormValidate = (where = document) => {
  const forms = where.querySelectorAll('form:not(.no-ajax)');
  if (forms.length) {
    forms.forEach((form) => submitForm(form));
  }
};

export {initFormValidate, validateFile, resetForm, checkInputValidity, showInputsError};
