const customTabs = () => {
  let tabsContainer = document.querySelectorAll('.tabs-container-js');

  if (!tabsContainer) {
    return;
  }

  tabsContainer.forEach((element) => {
    let tabs = element.querySelectorAll('.tab-js');

    element.addEventListener('click', (e) => {
      let target = e.target;
      tabs.forEach((tab) => {
        if (target.closest('[data-tab-button]')) {
          const button = target.closest('[data-tab-button]').dataset.tabButton;
          tab.classList.remove('active');

          setTimeout(() => {
            tab.classList.add('visually-hidden');
          }, 300);

          if (tab.dataset.tab === button) {
            setTimeout(() => {
              tab.classList.remove('visually-hidden');
            }, 300);
            tab.classList.add('active');
          }
        }
      });
    });
  });
};

export {customTabs};
