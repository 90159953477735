import Table from './Table';

const initTable = () => {
  const tables = document.querySelectorAll('.js-table');

  if (tables[0]) {
    const tablesClass = new Table(tables);

    tablesClass.init();
  }
};

export {initTable};
