import {renderTable} from './Table';

const FIVE_MEGA_BYTES_IN_BYTES = 314572800000000000000;

const loadInputs = document.querySelectorAll('.load-input');
const classes = ['is-success', 'is-size-error', 'is-load-error', 'is-progress'];

const removeClasses = (loadInput) => {
  classes.forEach((el) => {
    if (loadInput) {
      loadInput.classList.remove(el);
    }
  });
};

const initFileValidate = () => {
  loadInputs.forEach((loadInput) => {
    const inputFile = loadInput.querySelector('[data-type="file"]');

    if (!inputFile) {
      return;
    }

    const labelFileName = loadInput.querySelector('.load-input__title');
    const labelReset = loadInput.querySelector('.load-input__label-reset');
    const title = labelFileName.dataset.defaultlabel;
    const progress = loadInput.querySelector('.load-input__progress');
    const progressPercent = loadInput.querySelector('.load-input__percent');
    const url = loadInput.dataset.url;

    const uploadFile = () => {
      const file = inputFile.files[0];
      const form = inputFile.form;
      const formData = new FormData(form);
      //formData.append('upload', file);
      const ajax = new XMLHttpRequest();
      ajax.upload.addEventListener('progress', progressHandler, false);
      labelFileName.innerHTML = inputFile.files[0].name;
      ajax.addEventListener('load', completeHandler, false);
      ajax.addEventListener('error', errorHandler, false);
      ajax.addEventListener('abort', errorHandler, false);
      ajax.open('POST', url, true);

      ajax.onreadystatechange = function()
      {
        if (ajax.readyState === XMLHttpRequest.DONE && ajax.status === 200)
        {
          const data = JSON.parse(ajax.responseText);
          if (data.data && data.data.table) {
            renderTable(data.data);
          }
          //
        }
      }

      ajax.send(formData);
    };

    const progressHandler = (e) => {
      removeClasses(loadInput);
      loadInput.classList.add('is-progress');
      const percent = (e.loaded / e.total) * 100;
      progress.value = Math.round(percent);
      progressPercent.textContent = `${Math.round(percent)}%`;
    };

    const completeHandler = () => {
      removeClasses(loadInput);
      loadInput.classList.add('is-success');
      progress.value = 0;
    };

    const errorHandler = () => {
      removeClasses(loadInput);
      loadInput.classList.add('is-load-error');
    };

    labelReset.addEventListener('click', (e) => {
      e.preventDefault();
      inputFile.value = '';
      loadInput.classList.remove('is-success');
      labelFileName.innerHTML = title;

      const removeUrl = labelReset.dataset.removeUrl;
      const Request = new XMLHttpRequest();

      Request.open('GET', removeUrl, true);
      Request.send();

      Request.onreadystatechange = function () {
        if (Request.readyState === XMLHttpRequest.DONE && Request.status === 200) {
          const data = this.responseText;
        }
      };
    });

    inputFile.addEventListener('change', () => {
      const errorMessage = loadInput.querySelector('.js-form-error');
      if (inputFile.files[0].size > FIVE_MEGA_BYTES_IN_BYTES) {
        loadInput.classList.add('is-size-error');
        inputFile.value = '';
        inputFile.setAttribute('aria-invalid', 'true');
        errorMessage.classList.add('visible');
        return;
      }
      uploadFile();

      inputFile.setAttribute('aria-invalid', 'false');
      errorMessage.classList.remove('visible');
    });
  });
};

export {initFileValidate, removeClasses};
