import axios from 'axios';

const initAccountOrderCounter = () => {
  const orderDetailsOptions = document.querySelector('.js-order-details');
  const orderCategoriesGrid = document.querySelector('.js-categories-order');

  if (orderDetailsOptions) {
    orderDetailsOptions.addEventListener('click', (evt) => {
      const quantityReset = evt.target.closest('.order-details__quantity-reset');
      const quantityChangeBtn = evt.target.closest('.order-details__options .order-counter button');

      if (quantityReset) {
        const quantityControls = quantityReset.closest('.order-details__quantity-controls');
        const counterBlock = quantityControls.querySelector('.order-counter');
        const counter = counterBlock.querySelector('.order-counter__counter');
        const label = counterBlock.querySelector('label');

        quantityReset.classList.add('order-details__quantity-reset--hide');

        counter.value = 0;
        label.textContent = 0;
        counterBlock.classList.remove('is-active');
      }

      if (quantityChangeBtn) {
        const quantityControls = quantityChangeBtn.closest('.order-details__quantity-controls');
        const counterBlock = quantityControls.querySelector('.order-counter');
        const quantityResetCurrent = quantityControls.querySelector('.order-details__quantity-reset');
        const counter = quantityControls.querySelector('.order-counter__counter');

        if (quantityChangeBtn) {
          if (counter.value === '0') {
            quantityResetCurrent.classList.add('order-details__quantity-reset--hide');
          } else {
            quantityResetCurrent.classList.remove('order-details__quantity-reset--hide');
            counterBlock.classList.add('is-active');
          }
        }
      }
    });

    orderDetailsOptions.addEventListener('change', (evt) => {
      const counter = evt.target.closest('.order-counter__counter');

      if (counter) {

        const quantityControls = counter.closest('.order-details__quantity-controls');
        const quantityResetCurrent = quantityControls.querySelector('.order-details__quantity-reset');

        if (counter.value === '0' || counter.value === '') {
          quantityResetCurrent.classList.add('order-details__quantity-reset--hide');
        } else {
          quantityResetCurrent.classList.remove('order-details__quantity-reset--hide');
        }
      }
    });
  }

  if (orderCategoriesGrid) {
    orderCategoriesGrid.addEventListener('click', (evt) => {
      const quantityReset = evt.target.closest('.order-details__quantity-reset');

      /*if (quantityReset) {

        const thisItem = quantityReset.closest('.order-details__option');
        //thisItem.remove();
        const url = quantityReset.dataset.url;

        axios.post(url, {
          actionVar: 'remove-basket',
          sid: quantityReset.dataset.sid,
          code: quantityReset.dataset.code,
          eid: quantityReset.dataset.eid
        }).then((response) => {

        });
      }*/
    });
  }
};

export {initAccountOrderCounter};
