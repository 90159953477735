import {initLabelMoving} from './init-label-moving';
import {validateFile} from './init-form-validate';

const initUpload = () => {
  const containers = document.querySelectorAll('.add-file');

  if (!containers) {
    return;
  }

  containers.forEach((container) => {
    const inputLink = container.querySelector('[type="text"]');
    const fileInput = container.querySelector('[type="file"]');
    const buttonClear = container.querySelector('.button-clear');
    const inputWrapper = container.querySelector('.add-file__input-wrapper');

    const fileLoaded = ({target}) => {
      const fileName = fileInput.files[0].name;
      inputLink.value = fileName;
      validateFile(inputLink);
      inputLink.setAttribute('readonly', true);
      inputWrapper.classList.add('visually-hidden');
      buttonClear.classList.add('active');
      initLabelMoving();
    };

    const clearInput = () => {
      inputLink.value = '';
      inputLink.removeAttribute('readonly');
      inputWrapper.classList.remove('visually-hidden');
      buttonClear.classList.remove('active');
      initLabelMoving();
    };

    const linkCopy = () => {
      inputWrapper.classList.add('visually-hidden');
      buttonClear.classList.add('active');
    };

    fileInput.addEventListener('change', fileLoaded);
    buttonClear.addEventListener('click', clearInput);
    inputLink.addEventListener('input', linkCopy);
    inputLink.addEventListener('blur', () => {
      if (!inputLink.value) {
        clearInput();
      }
    });
  });

};
export {initUpload};
